import styled from 'styled-components';
import { Container as BootStrapContainer, Row } from 'react-bootstrap';

export const Container = ({ children, style }) => (
    <BootStrapContainer className="z-depth-5" style={style}>
        {children}
    </BootStrapContainer>
);

export const device = {
    xs: `@media (min-width: 380.98px)`,
    sm: `@media (min-width: 575.98px)`,
    md: `@media (min-width: 767.98px)`,
    lg: `@media (min-width: 991.98px)`,
    xl: `@media (min-width: 1199.98px)`,
};

// ICONS
import {
    FaEquals,
    FaPlus,
    FaMinus,
    FaStarOfLife,
    FaDivide,
    FaAngleDoubleRight,
    FaAngleDoubleLeft,
    FaAngleRight,
    FaAngleLeft,
} from 'react-icons/fa';

const fontSize = (props, size) => `${size * props.scalar || size}%`;

export const EqualSign = styled(FaEquals)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const PlusSign = styled(FaPlus)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const MinusSign = styled(FaMinus)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const MultiplySign = styled(FaStarOfLife)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const MultiplySignTest = styled(FaStarOfLife)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const DivideSign = styled(FaDivide)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const DoubleRightSign = styled(FaAngleDoubleRight)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const DoubleLeftSign = styled(FaAngleDoubleLeft)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const RightSign = styled(FaAngleRight)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

export const LeftSign = styled(FaAngleLeft)`
    font-size: ${(props) => fontSize(props, 130)};
    color: ${(props) => props.color || '#5b6b79'};
    ${device.md} {
        font-size: ${(props) => fontSize(props, 200)};
    }
`;

// Elements

export const DividerRow = styled(Row)`
    border-bottom: 1px solid dimgrey;
    -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
`;

export const CenteredDiv = styled('div')`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;

export const Bold = styled('b')`
    font-size: 100% ${device.md} {
        font-size: 110%;
    }
    ${device.lg} {
        font-size: 130%;
    }
`;

export const SmallItallic = styled('i')`
    color: lightgrey;
    fontsize: 80%;
`;

export const FormulaSizeSmall = styled('div')`
    font-size: ${(props) => `${100 * props.size || 100}%`};
    ${device.md} {
        font-size: ${(props) => `${180 * props.size || 180}%`};
    }
`;

export const FormulaSize = styled('div')`
    font-size: 130%;
    ${device.md} {
        font-size: 200%;
    }
`;

export const CenteredOnRightBorder = styled('div')`
    margin: 0;
    position: absolute;
    top: ${(props) => `${props.top || '50%'}`};
    right: 0px;
    -ms-transform: translate(+50%, -50%);
    transform: translate(+50%, -50%);
`;

export const CenteredOnLeftBorder = styled('div')`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;

export const NumberDiv = styled('div')`
    font-size: 125%;
    ${device.sm} {
        font-size: 140%;
    }
    ${device.md} {
        font-size: 200%;
    }
    line-height: 100%;
    background-color: #ededed;
    min-width: 35px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-bottom: 2px;
    text-align: center;
    border: 1px solid #e0e0e0;

    span {
        font-size: 80%;
        ${device.md} {
            vertical-align: 'sub';
        }
    }
`;



// Spinner
const StyledSpinner = styled.svg`
    animation: rotate 1s linear infinite;
    margin: 40px;
    width: 50px;
    height: 50px;

    & .path {
        stroke: #e28215;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

export const Spinner = () => (
    <StyledSpinner viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
    </StyledSpinner>
);

export const CenterContainer = styled.div`
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${device.md} {
        height: 800px;
    }
`;

export const CenteredSpinner = () => (
    <CenterContainer>
        <StyledSpinner viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
        </StyledSpinner>
    </CenterContainer>
);

// Combined Elements

export const RightSide = (WrappedComponent) => ({ outerProps, innerProps }) => {
    return (
        <CenteredOnRightBorder {...outerProps}>
            <WrappedComponent {...innerProps} />
        </CenteredOnRightBorder>
    );
};

export const LeftSide = (WrappedComponent) => ({ outerProps, innerProps }) => {
    return (
        <CenteredOnLeftBorder {...outerProps}>
            <WrappedComponent {...innerProps} />
        </CenteredOnLeftBorder>
    );
};

export const RightSidePlus = RightSide(PlusSign);
export const RightSideMinus = RightSide(MinusSign);
export const RightSideEqual = RightSide(EqualSign);
export const LeftSideEqual = LeftSide(EqualSign);
export const RightSideMultiply = RightSide(MultiplySign);
export const RightSideDivide = RightSide(DivideSign);
export const RightSideDoubleRightSign = RightSide(DoubleRightSign);
export const LeftSideDoubleLeftSign = LeftSide(DoubleLeftSign);

export const RightSideRightSign = RightSide(RightSign);
export const RightSideLeftSign = RightSide(LeftSign);
